export default [
    {
        name: 'Стандартный',
        subTitle: 'Курс для имеющих опыт работы по ремонту авто',
        days: 45,
        weeks: '5 дней',
        teory: 5,
        samostRabota: 35,
        price: '50 000 руб.',
        priceC: '55 000 руб.',
        priceDouble: '40 000 руб.',
        tarif: '1',
    },
    {
        name: 'Расширенный',
        subTitle: 'Курс для тех кто разбирается в базовой теории',
        days: 90,
        weeks: '10 дней',
        teory: 10,
        samostRabota: 70,
        price: '90 000 руб.',
        priceC: '95 000 руб.',
        priceDouble: '80 000 руб.',
        tarif: '2',
    },
    {
        name: 'Максимальный',
        subTitle: 'Курс для новичков',
        days: '15 дней',
        weeks: '3 недели',
        teory: 15,
        samostRabota: 110,
        price: '130 000 руб.',
        priceC: '140 000 руб.',
        priceDouble: '120 000 руб.',
        tarif: '3',
    },
];
