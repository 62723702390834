import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import NavSmall from '../Navs';
import Footer from '../Footer';

import Head from './Head';
import Map from './Map';
import styles from './styles.module.scss';


class Contacts extends Component {
  componentDidMount() {
    if (window.pageView) window.pageView('/contacts');
  }

  render() {
    return (
      <div className={styles.pageWrapper}>
        <Helmet>
          <meta name="description" content="Контакты автосервиса по ремонту вмятин без покраски - SABR " />
          <meta name="keywords" content="контакты, ремонт кузова, инструменты для ремонта, красить машину, покраска машины, ремонт вмятин,  вмятины на машине, pdr, вмятина, машина" />
          <title>Карта местоположения и контакты для связи | Автосервис "SABR"</title>
        </Helmet>
        <NavSmall />
    
        <Head />
    
        <Map />
    
        <Footer />
      </div>
    );
  }
}

export default Contacts;
