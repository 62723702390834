import React, { Component } from 'react';

import Img from '../../../components/Img';

import description1 from './imgs/description-1.png';
import description1webp from './imgs/description-1.webp';
import description2 from './imgs/description-2.png';
import description2webp from './imgs/description-2.webp';
import description3 from '../GalleryWork/Items/teach/A2-19.jpeg';

import styles from './styles.module.scss';

class Description extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.row}>

          <div className={styles.colText}>
            <h3>Чем данная технология выгодна?</h3>

            <p>
            <ul>
              <li>нет расходных материалов</li>
              <li>нет следов ремонта</li>
              <li>скорость ремонта выше</li>
              <li>регулярные повторные обращения от клиентов</li>
              <li>средняя цена ремонта 3-7 тысяч</li>
              <li>среднее время ремонта 1-3 часа</li>
            </ul>
            Это основные преимущества, которые выделяют вас среди других автосервисов использующих классический ремонт.
            </p>
          </div>

          <div className={styles.col}>
            <Img
              url={description1}
              urlWebp={description1webp}
              alt={"Безупречное качество"}
              width={'100%'}
              height={400}
            />
          </div>

        </div>
        <div className={styles.row}>

          <div className={styles.col}>
            <Img
              url={description2}
              urlWebp={description2webp}
              alt={"Безупречное качество"}
              width={'100%'}
              height={400}
            />
          </div>

          <div className={styles.colText}>
            <h3>Для чего правильное обучение важно для каждого желающего обучаться технологии выпрямления вмятин PDR?</h3>

            <p>
              Правильное обучение - это ваш фундамент, на котором будет строиться ваша дальнейшая карьера. Если этот фундамент будет некачественным, то ваш успех обречен на провал.
Чтобы не допустить провала, необходимо пройти качественное обучение.

            </p>
          </div>

        </div>
        <div className={styles.row}>

          <div className={styles.colText}>
            <h3>Вы получаете от нас:</h3>

            <p>
              <ul>
                <li>Мы организуем для вас проживание и обед на время обучения - это входит в цену обучения.</li>
                <li>Сертифицированного наставника, который будет вас обучать и отвечать на ваши вопросы</li>
                <li>Полный набор инструмента, на время обучения</li>
                <li>Знания и навыки, которые быстро окупят ваши вложения</li>
                <li>Поддержку после обучения.<br /><small>Даже после обучения, у вас будет возможность связаться с нами и задать необходимые вопросы. Также, предоставление услуг по продвижению вашего бизнеса, и привлечением клиентов.</small></li>
              </ul>
            </p>
          </div>

          <div className={styles.col}>
            <Img
              url={description3}
              width={'100%'}
              height={400}
            />
          </div>

        </div>
      </div>
    );
  }
}

export default Description;
