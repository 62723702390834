import React from 'react';
import { Helmet } from 'react-helmet';
import AOS from "aos";

import Navs from '../Navs';
import Footer from '../Footer';

import Head from './Head';
import Estimate from './Estimate';
import Reviews from './Reviews';

import GalleryHead from '../Gallery/Head';
import GalleryItems from '../Gallery/Items';
import styles from "./styles.module.scss";
import bestPrice from "./hand.svg";
import clock from "./clock.svg";
import insurance from "./insurance.svg";
import video1 from "../../portfolio/1/IMG_5583.MP4";
import portfolio1_1 from "../../portfolio/1/IMG_5584.JPG";
import portfolio1_2 from "../../portfolio/1/IMG_5585.JPG";

import portfolio2_1 from "../../portfolio/2/IMG_5574.JPG";
import portfolio2_2 from "../../portfolio/2/IMG_5577.JPG";

import portfolio3_1 from "../../portfolio/3/IMG_5579.JPG";
import portfolio3_1_2 from "../../portfolio/3/IMG_5580.JPG";
import portfolio3_2 from "../../portfolio/3/IMG_5581.JPG";
import portfolio3_2_2 from "../../portfolio/3/IMG_5582.JPG";
import video from "./Head/video.mp4";
import portfolio4_1_1 from "../../portfolio/4/IMG_5565.JPG";
import portfolio4_1_2 from "../../portfolio/4/IMG_5566.JPG";
import portfolio4_2_1 from "../../portfolio/4/IMG_5567.JPG";
import portfolio4_2_2 from "../../portfolio/4/IMG_5568.JPG";

import portfolio5_1 from "../../portfolio/5/IMG_5560.JPG";
import portfolio5_4 from "../../portfolio/5/IMG_5563.JPG";
import bgJpg from "../Gallery/Items/imgs/bg.jpg";
import HeadComponent from "../../components/Head";
import Gallery from "../Learning/GalleryWork/Items/Gallery";

const history1 = [];

for (let i = 1; i < 4; i++) {
    const urls = require(`./blue-car/${i}.jpeg`);
    history1.push({
        urls,
        desc: null,
    });
}


const Home = () => {
    AOS.init();

    const prices = [
        {
            id: 1,
            title: <>Ремонт вмятин от града свыше&nbsp;30шт</>,
            value: 'от 450 руб.',
        },
        {
            id: 2,
            title: <>Ремонт вмятин до&nbsp;1см</>,
            value: 'от 2000 руб.',
        },
        {
            id: 3,
            title: <>Ремонт вмятин до&nbsp;5см</>,
            value: 'от 4000 руб.',
        },
        {
            id: 4,
            title: <>Ремонт вмятин до&nbsp;8см</>,
            value: 'от 6000 руб.',
        },
        {
            id: 5,
            title: <>Ремонт вмятин до&nbsp;10см</>,
            value: 'от 8000 руб.',
        },
        {
            id: 7,
            title: <>Ремонт вмятин до&nbsp;20см</>,
            value: 'от 4500 руб.',
        },
        {
            id: 8,
            title: <>Ремонт вмятин до&nbsp;30см</>,
            value: 'от 5500 руб.',
        },
        {
            id: 9,
            title: 'Экспресс ремонт царапин',
            value: 'от 1500 руб.',
        },
        {
            id: 10,
            title: <>Ремонт сколов на&nbsp;кузове</>,
            value: 'от 1500 руб.',
        },
    ];
    return (
        <div>
            <Helmet>
                <meta name="description" content="Мы используем самые современные методы и инструменты для ремонта кузова, которые обеспечивают хороший внешний вид вашего автомобиля. Вам не приходится красить машину, что снижает цену на рынке при продаже." />
                <meta name="keywords" content="ремонт кузова, инструменты для ремонта, красить машину, покраска машины, ремонт вмятин,  вмятины на машине, pdr, вмятина, машина" />
                <title>Ремонт вмятин без покраски в Казани | Автосервис "SABR"</title>
            </Helmet>

            <Navs />

            <Head />

            <div id="next-block" style={{overflow: 'hidden'}}>
                <div className={styles.container}>
                    <div className={styles.advantages}>
                        <h3>Что такое технология PDR</h3>
                        <div>
                            <img src={bestPrice} />
                            <div>Стоимость ремонта по технологии PDR дешевле в несколько раз традиционного кузовного ремонта</div>
                        </div>
                        <div>
                            <img src={insurance} />
                            <div>Ремонт производится без шпаклевания и покраски. Отсутствие риска отличающегося цвета отремонтированной детали</div>
                        </div>
                        <div>
                            <img src={clock} />
                            <div>Ремонт вмятины в максимально короткие сроки. Не нужно ждать пока высохнет поверхность после покраски</div>
                        </div>
                    </div>
                </div>
                {/*<Infographs />*/}
                <div className={styles.container}>
                    <div className={styles.prices}>
                        {prices.map(i => (
                            <div key={i.id} className={styles.pricesItem}>
                                <span className="title">{i.title}</span>
                                <div className="value">{i.value}</div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.pricesInfo}>Стоимость ремонта так же зависит от растяжение металла и места нахождения вмятины</div>
                </div>

                <GalleryHead />
                <GalleryItems />

                {/*<HeadComponent*/}
                {/*    bgJpg={bgJpg}*/}
                {/*    bgWebp={null}*/}
                {/*    title="Галлерея работ"*/}
                {/*/>*/}
                <Gallery slides={history1} noPlay />



                <div className={styles.container}>
                    <div className={styles.portfolio}>
                        <div className={styles.portfolioItem}>
                            <div className={styles.portfolioPhotos}>
                                <div>
                                    <img src={portfolio2_1} alt="Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г." />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio2_2} alt="Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г." />
                                    <span>После</span>
                                </div>
                            </div>
                            <div className={styles.portfolioDesc}>
                                Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г.
                                Машине было все пару месяцев как она выехала с автосалона.
                                Клиент объездил многие кузовные цеха и ему везде говорили о замене крыла.
                                Он очень не хотел резать новую машину, которой всего пару месяцев.
                                По рекомендации вышел на нас и попросил оценить данное крыло. Обсудив все нюансы, мы взялись за эту работу.
                                Крыло восстанавливалась под покраску. Было очень сложно восстановить такое крыло, но нам это удалось.
                                Ремонт длился 9 дней, и затем мы передали машину в малярный цех. Клиент остался очень доволен.
                                Стоимость работы 45 000 руб.
                            </div>
                        </div>
                        <div className={styles.portfolioItem}>
                            <div className={styles.portfolioDesc}>
                                Данное крыло восстановили под локальную покраску.
                                Изначально ее нужно было менять, так как были серьезные растяжение металла.
                                Конечно, это крыло можно было бы восстановить классическим способом, но в этом случае
                                четверть крыла была бы в шпаклевке. При ремонте классическим способом, использовали бы
                                спотер, который оставляет микро дыры в металле.
                                В дальнейшем из этих микро дырок начинается коррозия и шпаклевка лопается.
                                Стоимость данного ремонта 25000 руб.
                            </div>
                            <div className={styles.portfolioPhotos}>
                                <div>
                                    <img src={portfolio5_1} alt="Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г." />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio5_4} alt="Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г." />
                                    <span>После</span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.portfolioItem}>
                            <div className={styles.portfolioPhotos}>
                                <div>
                                    <img src={portfolio1_2} alt="Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г." />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio1_1} alt="Очень сложная работа по восстановлению заднего крыла на AUDI A4 2020 г." />
                                    <span>После</span>
                                </div>
                            </div>
                            <div className={styles.portfolioDesc}>
                                Стоимость ремонта 12 000 руб. Сохранили заводскую дверь в оригинальном окрасе.
                                При продаже машина не потеряет в цене и такой ремонт не смогут обнаружить даже прибором.

                                <video src={video1} type="video/mp4" muted
                                       autoPlay preload="auto" controls loop="loop"></video>
                            </div>
                        </div>

                        <div className={styles.portfolioItemCenter}>
                            <div className={styles.portfolioDesc}>
                                Cтоимость данной работы 7500 руб. Время ремонта 8 часов.
                            </div>
                            <div className={styles.portfolioPhotos4}>
                                <div>
                                    <img src={portfolio3_1} alt="Cтоимость данной работы 7500 руб. Время ремонта 8 часов. Фотка до" />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio3_1_2} alt="Cтоимость данной работы 7500 руб. Время ремонта 8 часов. Фотка до" />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio3_2} alt="Cтоимость данной работы 7500 руб. Время ремонта 8 часов. Фотка после" />
                                    <span>После</span>
                                </div>
                                <div>
                                    <img src={portfolio3_2_2} alt="Cтоимость данной работы 7500 руб. Время ремонта 8 часов. Фотка после" />
                                    <span>После</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.portfolioItemCenter}>
                            <div className={styles.portfolioDesc}>
                                Cтоимость данного ремонта 5500. Время ремонта 5 часов.
                            </div>
                            <div className={styles.portfolioPhotos4}>
                                <div>
                                    <img src={portfolio4_1_1} alt="" />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio4_1_2} alt="" />
                                    <span>До</span>
                                </div>
                                <div>
                                    <img src={portfolio4_2_1} alt="" />
                                    <span>После</span>
                                </div>
                                <div>
                                    <img src={portfolio4_2_2} alt="" />
                                    <span>После</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Reviews />
                <Estimate onlyForm />

            </div>

            <div></div>

            <Footer />
        </div>
    );
}

export default Home;
