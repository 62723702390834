import React, {Component} from 'react';
import {loadReCaptcha} from 'react-recaptcha-v3'
import axios from 'axios';
import AOS from 'aos';

import resizeDimension from '../../../decorators/resizeDimension';

import {API_URL} from '../../../configs';

import styles from './styles.module.scss';

import Form from "./Form";


class Estimate extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isSuccessSend: false,
        }

        AOS.init();
    }

    componentDidMount() {
        loadReCaptcha('6Ld3xa4UAAAAAPJpVGfwynLZ55nf25ZeZx7QFPo7');
    }

    componentWillReceiveProps() {
        AOS.refresh();
    }

    handleSubmit = async (e, data) => {
        e.preventDefault();

        try {
            const res = await axios.post(`${API_URL}/mail`, {
                ...data,
                details: '',
                links: [],
            });
            if (res.data === 'Message has been sent sucseccful') {
                this.setState({isSuccessSend: true});
                if (window.reachGoal) window.reachGoal('send_estimate');
            } else {
                alert('Сообщение не было отправлено, попробуйте снова');
            }
        } catch (e) {
            console.error(e);
            alert('Сообщение не было отправлено, попробуйте снова');
        }
    }

    render() {
        const {
            isSuccessSend
        } = this.state;

        return (
            <div id="estimate" className={styles.fullContainer}>
                <div className={styles.estimateTopFormDark}>

                    <h4 style={{color: '#fff'}}>Напишите нам!</h4>
                    <Form
                        handleSubmit={this.handleSubmit}
                        isSuccessSend={isSuccessSend}
                    />
                </div>
            </div>
        );
    }
}

export default resizeDimension()(Estimate);
