import React, { Component, createRef } from 'react';

import NavLink from './NavLink';

import logo from '../../components/Logo/imgs/logo.svg';
import styles from './styles.module.scss';

class Navs extends Component {
  constructor() {
    super();

    this.state = {
      navFixed: false,
      isOpen: false,
    };

    this.mobileNavRef = createRef();
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll, false);
    window.addEventListener('popstate', this.closeMenu, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
    window.removeEventListener('popstate', this.closeMenu, false);
  }

  onScroll = () => {
    window.onscroll = () => {
        setTimeout(() => {
          if (window.scrollY && (window.scrollY - (window.previousScrollY || 0)) < 0) {
            this.setState(() => ({ navFixed: true }));
          } else {
            this.setState(() => ({navFixed: false}));
          }
          window.previousScrollY = window.scrollY - 0.1 * (window.scrollY - (window.previousScrollY || 0));
        }, 0);
    };
  };

  toggleMenu = (value) => {
    this.setState({
      isOpen: !value ? !this.state.isOpen : value
    });
    if (value) {
      document.addEventListener('click', this.handleOutsideClick, false);
    }
  };

  openMenu = () => this.toggleMenu(true);
  closeMenu = () => this.toggleMenu();

  handleOutsideClick = (e) => {
    if (!this.state.isOpen) {
      if (this.mobileNavRef.current.contains(e.target)) {
        return;
      }
    }
    document.removeEventListener('click', this.handleOutsideClick, false);

    this.closeMenu();
  }

  renderLinks = () => {
    return (<>
      <NavLink to="/" hash="#header-block">Ремонт вмятин</NavLink>
      <NavLink to="/learning">Обучение</NavLink>
      <NavLink to="/contacts">Контакты</NavLink>
    </>);
  }

  render() {
    const { isOpen } = this.state;
    return (
        <div
          className={this.state.navFixed ? styles.headerFixed : styles.header}
        >
          <div className={styles.col}>

            <button
              className={styles.mobileNavIcon}
              onClick={this.openMenu}
            />
            <nav
              className={isOpen ? styles.mobileNavIsOpen : styles.mobileNav}
              ref={this.mobileNavRef}
            >
              {this.renderLinks()}
              <b className={styles.navTitle}>Наш адрес:</b>
              <a 
                className={styles.geoLinkMobile}
                onClick={() => { if (window.reachGoal) window.reachGoal('click_address')}}
                href="https://goo.gl/maps/NY2Ra1g6psF2" 
                target="_blank" 
                rel="noopener noreferrer"
              ><small>г. Казань,</small> ул. Космонавтов, д. 73</a>
            </nav>

            <nav className={styles.navHead}>
              {this.renderLinks()}
            </nav>

          </div>
          <div className={styles.col}>
            <a 
              className={styles.telLink} 
              href="tel:89872289904" 
              onClick={() => {if (window.reachGoal) window.reachGoal('click_tel')}}
              target="_blank"
              rel="noopener noreferrer"
            >8 987 228-99-04</a>
          </div>
          <div className={styles.geocol}>
            <a 
              className={styles.geoLink} 
              href="https://goo.gl/maps/NY2Ra1g6psF2" 
              onClick={() => {if (window.reachGoal) window.reachGoal('click_address')}}
              target="_blank" 
              rel="noopener noreferrer"
            ><small>г. Казань,</small> ул. Космонавтов, д. 73</a>

          </div>
          <div className={styles.col}>
            <a 
              className={styles.logoMobile} 
              href="/"
              
            >
              <img src={logo} alt="SABR - автосервис по ремонту автомобилей разных марок"/>
            </a>

          </div>
        </div>
    )
  }
}

export default Navs;
