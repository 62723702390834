import React, { Component } from 'react';

import resizeDimension from '../../../decorators/resizeDimension';

import doPosle1 from './imgs/1.png';
import doPosle2 from './imgs/2.png';
import doPosle3 from './imgs/3.png';
import doPosle4 from './imgs/4.png';
import doPosle6 from './imgs/6.png';
import doPosle7 from './imgs/7.png';
import doPosle8 from './imgs/8.png';
import doPosle9 from './imgs/9.png';
import doPosle10 from './imgs/10.png';
import doPosle11 from './imgs/11.png';
import doPosle12 from './imgs/12.png';
import doPosle13 from './imgs/13.png';
import doPosle14 from './imgs/14.png';
import doPosle15 from './imgs/15.jpg';
import doPosle16 from './imgs/16.jpg';
import doPosle17 from './imgs/17.jpg';
import doPosle18 from './imgs/18.jpg';
import doPosle19 from './imgs/19.jpg';

import Gallery from "../../Learning/GalleryWork/Items/Gallery";
import bgJpg from '../../Gallery/Items/imgs/bg.jpg';

const slides = [
      {
        id: 4,
        urls: doPosle1,
        desc: null,
      },
      {
        id: 5,
        urls: doPosle2,
        desc: null,
      },
      {
        id: 6,
        urls: doPosle3,
        desc: null,
      },
      {
        id: 7,
        urls: doPosle4,
        desc: null,
      },
      {
        id: 9,
        urls: doPosle6,
        desc: null,
      },
      {
        id: 10,
        urls: doPosle7,
        desc: null,
      },
      {
        id: 11,
        urls: doPosle8,
        desc: null,
      },
      {
        id: 12,
        urls: doPosle9,
        desc: null,
      },
      {
        id: 13,
        urls: doPosle10,
        desc: null,
      },
      {
        id: 14,
        urls: doPosle11,
        desc: null,
      },
      {
        id: 15,
        urls: doPosle12,
        desc: null,
      },
      {
        id: 16,
        urls: doPosle13,
        desc: null,
      },
      {
        id: 17,
        urls: doPosle14,
        desc: null,
      },
      {
        id: 18,
        urls: doPosle15,
        desc: null,
      },
      {
        id: 19,
        urls: doPosle16,
        desc: null,
      },
      {
        id: 20,
        urls: doPosle17,
        desc: null,
      },
      {
        id: 21,
        urls: doPosle18,
        desc: null,
      },
      {
        id: 22,
        urls: doPosle19,
        desc: null,
      },
    ];


for (let i = 1; i < 11; i++) {
    try {
        const urls = require(`./imgs/e${i}.jpeg`);
        let desc = null;

        if (i >= 8 && i <= 11) {
            desc = 'Rolls Royce';
        }
        slides.push({
            urls,
            desc,
        });
    } catch {
        // do nothing
    }
}

slides.reverse()


class Reviews extends Component {
  render() {
    return (
      <Gallery
        slides={slides}
        bgJpg={bgJpg}
      />
    );
  }
}

export default resizeDimension()(Reviews);
