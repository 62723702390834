import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { API_URL } from '../../configs';

import NavSmall from '../Navs';
// import CtaBtns from 'components/CtaLearningBtns';
import Footer from '../Footer';

import Head from './Head';
import LearningEstimate from './LearningEstimate';
import Description from './Description';
import GalleryWorkHead from './GalleryWork/Head';
import GalleryWorkItems from './GalleryWork/Items';

import GalleryHead from './Gallery/Head';
import GalleryItems from './Gallery/Items';
import WhyPdrBest from './WhyPdrBest';
import Tarifs from './Tarifs';

import styles from './styles.module.scss';

import advantages1 from './1.png';
import advantages2 from './2.png';
import advantages3 from './3.png';
import advantages4 from './4.png';
import advantages5 from './5.png';

class Learning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tarif: '1',
      phone: '',
      name: '',
      checkPolicy: false,
      isSuccessSend: false,
    };
  }

  handleChangeTarif = (tarif) => {
    this.setState({
      tarif,
    });
  }

  handleChange = (e) => {
    const value = e.target.value;
    const prop = e.target.name;
    console.log('value', value);
    console.log('prop', prop);
    this.setState({
      [prop]: value,
    });
  }

  handleCheck = (e) => {
    const { checkPolicy } = this.state;

    this.setState({
      checkPolicy: !checkPolicy,
    });
  }

  handleSubmit = (e, data) => {
    e.preventDefault();

    axios.post(`${API_URL}/learning-request`, {
      ...data,
    })
      .then((res) => {
        if (res.data === 'Message has been sent') {
          this.setState({isSuccessSend: true});
          if (window.reachGoal) window.reachGoal('send_estimate');
        } else {
          alert('Сообщение не было отправлено, попробуйте снова');
        }
      })
      .catch((e) => {
        alert('Сообщение не было отправлено, попробуйте снова')
      });
  }

  componentDidMount() {
    if (window.pageView) window.pageView('/our-team');
  }

  render() {
    const {
      phone,
      name,
      tarif,
      checkPolicy,
      isSuccessSend,
    } = this.state;

    return (
      <div>
        <Helmet>
          <meta name="description" content="Обучение ремонту вмятин без покраски в Казани за короткий срок" />
          <meta name="keywords" content="список услуг, услуги автосервиса, ремонт кузова, инструменты для ремонта, красить машину, покраска машины, ремонт вмятин,  вмятины на машине, pdr, вмятина, машина, компания по ремонту вмятин, обучение ремонту вмятин, обучение пдр, обуучение PDR" />
          <title>Курс обучения ремонту вмятин без покраски  | Автосервис "SABR"</title>
        </Helmet>
        <NavSmall />

        <Head />

        <div className={styles.container}>
          <div className={styles.textCenter}>
          Мы обучим тебя современной технологии PDR с нуля используя теорию и практику с разными видами вмятин
          </div>
        </div>

        <Description />

        <div className={styles.container}>
          <div className={styles.textCenter2}>
            <div>Начать обучение вы можете в любой день.<br />Занятия проходят по будним дням</div>
            <div>С 10:00 до 18:00</div>
          </div>
        </div>

        <GalleryWorkHead />
        <GalleryWorkItems />

        <div className={styles.container}>
        <div className={styles.advantages}>
          <h3>Все начинается с теории, которую необходимо знать перед началом практики.</h3>
          <div>
            <img src={advantages1} />
            <div>Все начинается с теории, которую необходимо знать перед началом практики.</div>
          </div>
          <div>
            <img src={advantages2} />
            <div>Тренировочные образцы с повреждениями, которые дадут вам базовые навыки ремонта вмятин, совместно с преподавателем.</div>
          </div>
          <div>
            <img src={advantages3} />
            <div>Обучение особым приемам из личного опыта, которые ускоряют процесс разборки/сборки какого-либо элемента, а также позволяют ускорить процесс самого ремонта без вреда для качества.</div>
          </div>
          <div>
            <img src={advantages4} />
            <div>Наша студия и класс обучения находятся в одном здании. Вы будете иметь возможность наблюдать за всеми рабочими процессами нашей студии и изучать ее, задавая нам любые вопросы.</div>
          </div>
          <div>
            <img src={advantages5} />
            <div>Если вы рассматриваете обучение для бизнеса, мы расскажем вам всю внутреннюю кухню нашего бизнеса, и вы поймете принцип нашего успеха.</div>
          </div>
        </div>
        </div>

        <WhyPdrBest />

        <GalleryHead />
        <GalleryItems />

        <Tarifs handleChangeTarif={this.handleChangeTarif} />
    
        <div id="cta-block" style={{overflow: 'hidden'}}>
          <LearningEstimate
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handleCheck={this.handleCheck}
            phone={phone}
            name={name}
            tarif={tarif}
            checkPolicy={checkPolicy}
            isSuccessSend={isSuccessSend}
          />
        </div>
    
        <Footer />
      </div>
    );
  }
}

export default Learning;
