import React, { Component } from 'react';

import resizeDimension from '../../../../decorators/resizeDimension';
import img2 from './imgs/img2-2.jpg';
import img3 from './imgs/img4-2.jpg';
import img4 from './imgs/img6-2.jpg';
import img5 from './imgs/img7.png';
import img6 from './imgs/img8.png';
import Gallery from "./Gallery";

import bgJpg from './imgs/bg.jpg';

const images = [img2, img3, img4, img5, img6];

for (let i = 0; i < 19; i++) {
  const url = require(`./teach/A2-${i}.jpeg`);
  images.push(url);
}

class Reviews extends Component {
  render() {

    return (
      <Gallery
        slides={images.map((urls) => ({
          urls,
          desc: null,
        }))}
        bgJpg={bgJpg}
      />
    );
  }
}

export default resizeDimension()(Reviews);
