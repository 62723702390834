import React, {Component} from 'react';
import {ReCaptcha} from 'react-recaptcha-v3';
import InputMask from 'react-input-mask';
import styles from './styles.module.scss';

class Form extends Component {
    constructor() {
        super();

        this.state = {
            phone: '',
            message: '',
            checkPolicy: false,
            submitted: false,
        };
    }

    handleChange = (e) => {
        const value = e.target.value;
        const prop = e.target.name;
        this.setState({
            [prop]: value,
        });
    }

    handleCheck = () => {
        const {checkPolicy} = this.state;

        this.setState({
            checkPolicy: !checkPolicy,
        });
    }

    render() {
        const {
            phone,
            message,
            checkPolicy,
            submitted,
        } = this.state;

        const {
            handleSubmit,
            isSuccessSend,
        } = this.props;

        return (
            <div className={styles.formSend}>
                <ReCaptcha
                    sitekey="6Ld3xa4UAAAAAPJpVGfwynLZ55nf25ZeZx7QFPo7"
                    action='estimate'
                />
                <form
                    onSubmit={async (e) => {
                        this.setState({submitted: true})
                        await handleSubmit(e, {
                            contact: phone,
                            message,
                        })
                        this.setState({submitted: false})
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    <div className={styles.formGroup}>
                        <InputMask
                            mask="+7 (999) 999-99-99"
                            placeholder="Номер телефона"
                            name="phone"
                            type="tel"
                            value={phone}
                            className={styles.textField}
                            onChange={this.handleChange}
                        />

                    </div>
                    <div className={styles.formGroup}>
            <textarea
                name="message"
                value={message}
                className={styles.textField}
                placeholder="Доп. примечания (модель машины, пожелания и т.д)"
                onChange={this.handleChange}
            >
            </textarea>
                    </div>
                    <div className={styles.formGroup}>
                        <input
                            type="checkbox"
                            name="checkPolicy"
                            id="checkPolicy"
                            className={styles.checkbox}
                            onChange={this.handleCheck}
                            defaultChecked={checkPolicy}
                        />
                        <label htmlFor="checkPolicy">Я даю согласие на обработку персональных данных.</label>
                    </div>

                    <div className={styles.formBtnGroup}>
                        <input
                            type="submit"
                            value={isSuccessSend ? "Заявка отправлена!" : "Отправить заявку"}
                            className={styles.submitBtn}
                            disabled={!checkPolicy || !phone || submitted || isSuccessSend}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.recaptcha} htmlFor="">Сайт защищен reCAPTCHA и Google <a
                            href="https://policies.google.com/privacy">Политика конфиденциальности</a> и <a
                            href="https://policies.google.com/terms">Условия обслуживания</a> применяются.</label>
                    </div>
                </form>
            </div>
        );
    }
}

export default Form;
