import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';
import prices from './prices';

class Header extends Component {
  render() {
    const {
      handleChangeTarif,
    } = this.props;
    return (
        <>
      <div className={styles.fullContainer}>
        <div className={styles.head}>
          <div className={styles.headWrapper}>
            <h3 className={styles.headTitle}>Программа обучения</h3>
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.tarifs}>
            {prices.map((i, key) => (
              <div key={`tarifItem${key}`} className={styles.tarifsItem}>
                <div className={styles.tarifsItemHead}>
                  <p>{i.name}</p>
                  <span>{i.subTitle}</span>
                </div>
                <div className={styles.tarifsItemBody}>
                  <ul>
                    <li>{i.weeks}</li>
                    {/*<li>{i.teory} ч. теоретических занятий</li>*/}
                    {/*<li>{i.samostRabota} часов практика</li>*/}
                    {/* <li>Практика на учебных элементах</li> */}
                    <li>Зарплата от 150к до 250к руб.</li>
                  </ul>

                  <div className={styles.price}>
                    <span>{i.price}</span> <del>{i.priceC}</del>
                  </div>

                  <div className={styles.doubleprice}>
                    <p>{i.priceDouble}</p>
                    <span>цена за друга*</span>
                  </div>
                </div>
                <div className={styles.tarifsItemBtn}>
                  <HashLink
                      smooth
                      to="/learning#details"
                      className={styles.btnSecondary}
                  >
                    <span>Сравнить программы</span>
                  </HashLink>
                <HashLink
                  smooth
                  to="/learning#cta-block"
                  onClick={() => {
                    if (window.reachGoal) window.reachGoal('click_free_estimate');
                    handleChangeTarif(i.tarif);
                  }}
                  className={styles.btn}
                >
                  <span>Выбрать программу</span>
                </HashLink>
                </div>
              </div>
            ))}

          </div>

          <div className={styles.tarifsInfo}>* - скидка распространяется на стоимость обучения второго ученика</div>
        </div>
      </div>


          <div id="details" className={styles.fullContainer}>
            <div className={styles.head}>
              <div className={styles.headWrapper}>
                <h3 className={styles.headTitle}>Сравнение курсов обучения по ремонту вмятин</h3>
              </div>
            </div>
          <div className={styles.body}>
          <div className={styles.table}>
            <table>
              <thead>
              <tr>
                <th>Курс</th>
                <th>Стандарт (5 дней)</th>
                <th>Расширенный (10 дней)</th>
                <th>Максимальный (15 дней)</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><strong>Основные навыки</strong></td>
                <td>
                  <ul>
                    <li>Ознакомление с инструментами</li>
                    <li>Правильная установка света</li>
                    <li>Подъём и осаживание градовых вмятин</li>
                    <li>Выравнивание рубцевидных вмятин (кризов)</li>
                    <li>Работа с вмятинами на ребре жёсткости</li>
                    <li>Работа с горячим и холодным клеем</li>
                    <li>Подготовка автомобиля к сдаче клиенту</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Все навыки стандарта</li>
                    <li className={styles.advanced}>Техника ремонта вмятин без покраски</li>
                    <li className={styles.advanced}>Работа с вмятинами под усилителями</li>
                    <li className={styles.advanced}>Работа в труднодоступных местах</li>
                    <li className={styles.advanced}>Принципы работы с краями элементов</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Все навыки расширенного курса</li>
                    <li className={styles.advanced}>Работа с растянутыми вмятинами</li>
                    <li className={styles.advanced}>Техника работы с аппаратом Hosting</li>
                    <li className={styles.advanced}>Работа с порогами</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>Дополнительные преимущества</strong></td>
                <td>
                  <ul>
                    <li>10% скидка на инструмент</li>
                    <li>Подбор комплекта инструмента для начального уровня</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>10% скидка на инструмент</li>
                    <li>Подбор комплекта инструмента для начального уровня</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>10% скидка на инструмент</li>
                    <li>Подбор комплекта инструмента для начального уровня</li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          </div>
          </div>
        </>
    );
  }
}

export default Header;
